import React from 'react';
import "../style/main.css";
import "../style/responsive.css";
import TitaAminah from "../images/1guru-Hj-TITA.jpg"
import Irma from "../images/2guru-IRMA.jpg"
import Atjep from "../images/5guru-ATJEP.jpg"
import AaMustopa from "../images/7guru-AA-MUSTOFA.jpg"
import ImasYani from "../images/9guru-IMAS.jpg"
import DandanS from "../images/10guru-Dandan-Syaripudin.S.jpg"
import AtangWarman from "../images/11guru-ATANG.jpg"
import TitaRosmala from "../images/12guru-Tita-Rosmala.jpg"
import YoppyMarta from "../images/13guru-YOPPY.jpg"
import AepAminuddin from "../images/14guru-H-Aep.jpg"
import IyusIsmail from "../images/15guru-iyus.jpg"
import Ponpon from "../images/16guru-Hj-PONPON.jpg"
import Mulyana from "../images/17guru-MULYANA.jpg"
import KikiMurtaqilah from "../images/18guru-H-Kiki.jpg"
import Hilman from "../images/19guru-Hilman_.jpg"
import Sony from "../images/20guru-SONY.jpg"
import Erwan from "../images/21guru-erwan.jpg"
import Kosong from "../images/noImage.png"
const Profile = () => {
  return (
    <div className="card feature mb-3">
    <div className="team">
      <div className="container">
        <div className="row">
          <div className="col">
            <div className="section_title_container text-center">
              <h2 className="section_title">GURU SMP TRIYASA</h2>
            </div>
          </div>
        </div>
        <div className="row team_row">

          {/* Team Item 1 */}
          <div className="col-lg-3 col-md-6 team_col">
            <div className="team_item">
              <div className="team_image img-fluid"><img src={TitaAminah} alt="kepala perpustakaan" /></div>
              <div className="team_body">
                <div className="team_title px-2"><a href="#">Tita Aminah, S.Pd</a></div>
                
                <div className="team_subtitle">Matematika</div>
                
              </div>
            </div>
          </div>

          {/* Team Item 2 */}
          <div className="col-lg-3 col-md-6 team_col">
            <div className="team_item">
              <div className="team_image img-fluid"><img src={Irma} alt="kepala Laboratorium" /></div>
              <div className="team_body">
                <div className="team_title px-2"><a href="#">Irma Erawati, S.Pd<br/></a></div>
                
                <div className="team_subtitle">Seni Budaya</div>
                
              </div>
            </div>
          </div>

          {/* Team Item 3 */}
          <div className="col-lg-3 col-md-6 team_col">
            <div className="team_item">
              <div className="team_image img-fluid"><img src={Atjep} alt="wakases urusan kesiswaan" /></div>
              <div className="team_body">
                <div className="team_title px-2"><a href="#">Atjep Heriawan, S.Pd.I</a></div>
                
                <div className="team_subtitle">Seni Budaya</div>
              
              </div>
            </div>
          </div>

          {/* Team Item 4 */}
          <div className="col-lg-3 col-md-6 team_col">
            <div className="team_item">
              <div className="team_image img-fluid"><img src={AaMustopa} alt="Kurikulum" /></div>
              <div className="team_body">
                <div className="team_title px-2"><a href="#">Aa Mustopa, S.Ag</a></div>
                <div className="team_subtitle">PAI</div>
              </div>
            </div>
          </div>
        </div>
        <div className="row team_row">

          {/* Team Item 1 */}
          <div className="col-lg-3 col-md-6 team_col">
            <div className="team_item">
              <div className="team_image img-fluid"><img src={ImasYani} alt="Osis" /></div>
              <div className="team_body">
                <div className="team_title px-2"><a href="#">Imas Yani, SP</a></div>
                <div className="team_subtitle">IPA</div>
                
              </div>
            </div>
          </div>

          {/* Team Item 2 */}
          <div className="col-lg-3 col-md-6 team_col">
            <div className="team_item">
              <div className="team_image img-fluid"><img src={DandanS} alt="Pembina Osis" /></div>
              <div className="team_body">
                <div className="team_title px-2"><a href="#">Dandan Syaripudin, S.Pd<br/></a></div>
                <div className="team_subtitle">PJOK</div>
              
              </div>
            </div>
          </div>

          {/* Team Item 3 */}
          <div className="col-lg-3 col-md-6 team_col">
            <div className="team_item">
              <div className="team_image img-fluid"><img src={AtangWarman} alt="UKS" /></div>
              <div className="team_body">
                <div className="team_title px-2"><a href="#">H. Atang Warman, S.Pd</a></div>
                <div className="team_subtitle">PJOK</div>
              </div>
            </div>
          </div>

          {/* Team Item 4 */}
          <div className="col-lg-3 col-md-6 team_col">
            <div className="team_item">
              <div className="team_image img-fluid"><img src={TitaRosmala} alt="Laboratorium Komputer" /></div>
              <div className="team_body">
                <div className="team_title px-2"><a href="#">Tita Rosmala</a></div>
                <div className="team_subtitle">Bahasa Sunda</div>
              </div>
            </div>
          </div>
        
        {/* Team Item 4 */}
        <div className="col-lg-3 col-md-6 team_col">
            <div className="team_item">
              <div className="team_image img-fluid"><img src={YoppyMarta} alt="Laboratorium Komputer" /></div>
              <div className="team_body">
                <div className="team_title px-2"><a href="#">Yoppy Marta, S.Pd</a></div>
                <div className="team_subtitle">PJOK</div>
              </div>
            </div>
          </div>
        {/* Team Item 4 */}
        <div className="col-lg-3 col-md-6 team_col">
            <div className="team_item">
              <div className="team_image img-fluid"><img src={AepAminuddin} alt="Laboratorium Komputer" /></div>
              <div className="team_body">
                <div className="team_title px-2"><a href="#">Ir.H.Aep Aminuddin</a></div>
                <div className="team_subtitle">IPA</div>
              </div>
            </div>
          </div>
        {/* Team Item 4 */}
        <div className="col-lg-3 col-md-6 team_col">
            <div className="team_item">
              <div className="team_image img-fluid"><img src={IyusIsmail} alt="Laboratorium Komputer" /></div>
              <div className="team_body">
                <div className="team_title px-2"><a href="#">Iyus Ismail, S.Pd</a></div>
                <div className="team_subtitle">Bahasa Indonesia</div>
              </div>
            </div>
          </div>
        {/* Team Item 4 */}
        <div className="col-lg-3 col-md-6 team_col">
            <div className="team_item">
              <div className="team_image img-fluid"><img src={Ponpon} alt="Laboratorium Komputer" /></div>
              <div className="team_body">
                <div className="team_title px-2"><a href="#">Hj. Ponpon Sugihartini, S.Pd</a></div>
                <div className="team_subtitle">IPS</div>
              </div>
            </div>
          </div>

{/* Team Item 4 */}
          <div className="col-lg-3 col-md-6 team_col">
            <div className="team_item">
              <div className="team_image img-fluid"><img src={Mulyana} alt="Laboratorium Komputer" /></div>
              <div className="team_body">
                <div className="team_title px-2"><a href="#">Mulyana, S.Pd</a></div>
                <div className="team_subtitle">Bahasa Indonesia</div>
              </div>
            </div>
          </div>
{/* Team Item 4 */}
<div className="col-lg-3 col-md-6 team_col">
            <div className="team_item">
              <div className="team_image img-fluid"><img src={KikiMurtaqilah} alt="Laboratorium Komputer" /></div>
              <div className="team_body">
                <div className="team_title px-2"><a href="#">H. Kiki Murtaqillah, S.Ag</a></div>
                <div className="team_subtitle">PAI</div>
              </div>
            </div>
          </div>
{/* Team Item 4 */}
<div className="col-lg-3 col-md-6 team_col">
            <div className="team_item">
              <div className="team_image img-fluid"><img src={Hilman} alt="Laboratorium Komputer" /></div>
              <div className="team_body">
                <div className="team_title px-2"><a href="#">Hilman Saepul K, S.Pd</a></div>
                <div className="team_subtitle">Matematika</div>
              </div>
            </div>
          </div>
{/* Team Item 4 */}
<div className="col-lg-3 col-md-6 team_col">
            <div className="team_item">
              <div className="team_image img-fluid"><img src={Sony} alt="Laboratorium Komputer" /></div>
              <div className="team_body">
                <div className="team_title px-2"><a href="#">Sony Iskandar, S.Pd</a></div>
                <div className="team_subtitle">Bahasa Sunda</div>
              </div>
            </div>
          </div>
{/* Team Item 4 */}
<div className="col-lg-3 col-md-6 team_col">
            <div className="team_item">
              <div className="team_image img-fluid"><img src={Erwan} alt="Laboratorium Komputer" /></div>
              <div className="team_body">
                <div className="team_title px-2"><a href="#">Erwana Widyantara, SE</a></div>
                <div className="team_subtitle">Informatika</div>
              </div>
            </div>
          </div>
{/* Team Item 4 */}
<div className="col-lg-3 col-md-6 team_col">
            <div className="team_item">
              <div className="team_image img-fluid"><img src={Kosong} alt="Laboratorium Komputer" /></div>
              <div className="team_body">
                <div className="team_title px-2"><a href="#">Azka Shakiran PT, S.S</a></div>
                <div className="team_subtitle">Bahasa Inggris</div>
              </div>
            </div>
          </div>
          {/* Team Item 4 */}
          <div className="col-lg-3 col-md-6 team_col">
            <div className="team_item">
              <div className="team_image img-fluid"><img src={Kosong} alt="Laboratorium Komputer" /></div>
              <div className="team_body">
                <div className="team_title px-2"><a href="#">Hasna Shofiyah, S.Pd</a></div>
                <div className="team_subtitle">Bahasa Indonesia</div>
              </div>
            </div>
          </div>
          {/* Team Item 4 */}
          <div className="col-lg-3 col-md-6 team_col">
            <div className="team_item">
              <div className="team_image img-fluid"><img src={Kosong} alt="Laboratorium Komputer" /></div>
              <div className="team_body">
                <div className="team_title px-2"><a href="#">Suryana, S.Pd</a></div>
                <div className="team_subtitle">Bahasa Inggris</div>
              </div>
            </div>
          </div>
          {/* Team Item 4 */}
          <div className="col-lg-3 col-md-6 team_col">
            <div className="team_item">
              <div className="team_image img-fluid"><img src={Kosong} alt="Laboratorium Komputer" /></div>
              <div className="team_body">
                <div className="team_title px-2"><a href="#">Sri Asih U, S.Pd</a></div>
                <div className="team_subtitle">IPS</div>
              </div>
            </div>
          </div>
          {/* Team Item 4 */}
          <div className="col-lg-3 col-md-6 team_col">
            <div className="team_item">
              <div className="team_image img-fluid"><img src={Kosong} alt="Laboratorium Komputer" /></div>
              <div className="team_body">
                <div className="team_title px-2"><a href="#">Asri Iyasha SF, S.Pd</a></div>
                <div className="team_subtitle">PPKn</div>
              </div>
            </div>
          </div>


        </div>
      </div>
    </div>
    </div>
  );
};

export default Profile;
