import React from 'react';
import "../style/main.css"
import Gambar from "../images/Upacara.jpg";
const PopularCourses = () => {
  return (
    <div className="card feature mb-3">
    <section class="mb-5 mb-lg-10 pt-5">
      <h2 class="fw-bold text-center mb-5 pt-5">VISI MISI SMP TRIYASA</h2>

      <div class="row gx-xl-5">
        <div class="col-lg-6 col-md-12 mb-4 mb-lg-0">

          <div class="card">
            <div class="card-header text-center">
              <h2 class="text-uppercase">
                <strong>Visi</strong>
              </h2>
            </div>
            <div class="card-body">

              <h5 class ="text-center"><strong>“MEWUJUDKAN PESERTA DIDIK YANG BERKARYA KOMPLITS"</strong> <br></br> 
Berbudaya, Berkarakter, Berkompetensi, Berwawasan Lingkungan Sehat, dan Smart
</h5>
            </div>
          </div>

        </div>

        <div class="col-lg-6 col-md-6 mb-4 mb-lg-0">

          <div class="card">
            <div class="card-header text-center">
              <h2 class="text-uppercase">
                <strong>Misi</strong>
              </h2>
            </div>
            <div class="card-body">
              <p><ul>
              <li>1.	Meningkatkan Karakter  peserta didik sesuai dengan Profil Pelajar Pancasila.</li>
              <li>2.	Menigkatkan budaya membaca dan mengembangkan kegiatan literasi sekolah sehingga peserta didik dapat bernalar kritis.</li>
              <li>3.	Meningkatkan budaya lokal.</li> 
              <li>4.	Meningkatkan Kompetensi Akademik dan Non Akademik.</li>
              <li>5.	Menciptkan lingkungan sekolah yang bersih, sehat, aman, nyaman dan menyenangkan serta adaptif terhadap perubahan zaman.</li>
              <li>6.	Menciptakan pembelajaran yang inovatif, kreatif, menarik, menyenangkan dan adaptif terhadap perkembangan zaman dan teknologi untuk memfasilitasi peserta didik sesuai bakat dan minatnya.</li>
              </ul>
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
    </div>
  );
};

export default PopularCourses;