import React, { useState, useMemo } from 'react';
import "../style/map.css"
import { MDBRow, MDBCol } from 'mdb-react-ui-kit';

export default function App() {
  return (
    <div className="card feature mb-3">
    <div className="map">
    <div className="pt-5">
      <div className="container">
        <div className="row">
          <div className="col">
            <div className="section_title_container text-center">
              <h2 className="section_title">LOKASI SEKOLAH</h2>
            </div>
          </div>
        </div>
      </div>
    </div>
    <MDBRow className='w-100 justify-content-center mt-0'>
      <MDBCol lg='4' className='my-4'>
        <iframe
          src='https://www.google.com/maps/embed?pb=!!1m18!1m12!1m3!1d3960.81201769303!2d107.70053587399731!3d-6.913066293086423!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e68dd3ac0bb3727%3A0x111e6d43011d9c89!2sSekolah%20Menengah%20Pertama%20Triyasa!5e0!3m2!1sid!2sid!4v1723302864996!5m2!1sid!2sid'
          className='w-100'
          height='400'
          loading='lazy'
        ></iframe>
      </MDBCol>
      <MDBCol lg='6' className='my-3 d-flex align-items-center'>
        <div>
          <h6>DETAIL LOKASI SMP TRIYASA</h6>
          <p>
          Jl. Nagrog No.9, Pasirwangi, Kec. Ujung Berung, Kota Bandung, Jawa Barat 40618
          </p>
          <p>
          Titik Koordinat -6.912906530514249, 107.70312152345423
          </p>
          
        </div>
      </MDBCol>
    </MDBRow>
</div>
  </div>
  );
}