import { Nav, Navbar} from "react-bootstrap";
import Footer from "../components/Footer.js";
import SideCard from "../components/SideCard.js";
import ProfileGuru from "../components/ProfileGuru";
import ProfileTri from "../components/ProfileTri.js";
import Map from "../components/Map.js";
import logo from '../images/logotriyasa.png';
import "../style/main.css";
import "../style/responsive.css";
import { FiPhone } from 'react-icons/fi';
import { FiMap } from "react-icons/fi";
import { IoMdMail } from 'react-icons/io';
import { MDBContainer } from 'mdb-react-ui-kit';
import Marquee from "react-fast-marquee";

const ProfilSekolah = () => (
  <>
    
		<header class="header">
      <div class="top_bar">
			<div class="top_bar_container">
				<div class="container">
					<div class="row">
						<div class="col">
							<div class="top_bar_content d-flex flex-row align-items-center ">
								<Marquee>
								<ul class="top_bar_contact_list justify-content-start ">
									<li><div class="question">SELAMAT DATANG DI WEBSITE SMP TRIYASA BANDUNG </div></li>
									<li>
									<FiMap size={20} style={{ color: "white" }}/>
										<div>Jalan Nagrog No.09 Ujungberung Bandung Jawa Barat</div>
									</li>
									<li>
									<IoMdMail size={20} style={{ color: "white" }}/>
										<div>admin@smptriyasabdg.sch.id</div>
									</li>
									<li>
									<FiPhone size={20} style={{ color: "white" }}/>
										<div>(022)7800422</div>
									</li>
								</ul></Marquee>
								{/* <div class="top_bar_login mx-auto">
									<div class="login_button"><a href="#">Login</a></div>
								</div> */}
							</div>
						</div>
					</div>
				</div>
			</div>				
		</div>
		<Navbar className="main_nav_contaner w-100 pt-0" collapseOnSelect expand="lg" bg="light" variant="light">
			<Navbar.Brand className="header_content d-flex flex-row align-items-center">
				<MDBContainer fluid className="logo_container justify-content-start" style={{ width: '100%' }}>
				<a href="#">
					<div className="logo_text" style={{ fontSize: 'clamp(20px, 2vw, 40px)'}}>
					<img src={logo} alt="no image" className="img-responsive" style={{ minWidth: '20px', maxWidth: '30px'}} /> SMP TRIYSA <span> BANDUNG</span>
					</div>
				</a>
				</MDBContainer>
			</Navbar.Brand>
			<Navbar.Toggle aria-controls="responsive-navbar-nav" className="mx-4 justify-content-end" />
			<Navbar.Collapse className="mx-4 pl-lg-5 justify-content-center" id="responsive-navbar-nav">
					<Nav className="main_nav_contaner pl-lg-5 justify-content-end">
					<Nav.Link href={"/"}>Home</Nav.Link>
					<Nav.Link className="active" href={"/profilSekolah"}>Profil</Nav.Link>
					
					</Nav>
			</Navbar.Collapse>
		</Navbar>
	</header>
    <main className="my-5 py-5">
	<MDBContainer>
      {/* <Container className="h-100"> */}
            <SideCard />
	</MDBContainer> 	
	<MDBContainer>
			<ProfileTri/>
	</MDBContainer>
	<MDBContainer>
			<ProfileGuru />
			</MDBContainer>
	  <MDBContainer>
	  <Map />
	  </MDBContainer>
      {/* </Container> */}
    </main>

    <Footer />
  </>
);

export default ProfilSekolah;