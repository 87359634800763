import React from 'react';
import "../style/main.css"
import "../style/responsive.css"
import ROOTS from "../images/uploadRoots.png"
import PADUS from "../images/uploadPadus.png"
import PRAMUKA from "../images/uploadPramuka.png"
import PMR from "../images/uploadPMR.png"
import EC from "../images/uploadEC.png"
import FUTSAL from "../images/uploadFutsal.png"
import BTQ from "../images/uploadBTQ.png"
import TARI from "../images/uploadTari.png"


const FeaturesSection = () => {
  return (
    <div className="card feature mb-3">
    <div className="features2">
      <div className="container">
        <div className="row">
          <div className="col">
            <div className="section_title_container text-center">
              <h2 className="section_title">EKSTRAKULIKULER</h2>
              <div className="section_subtitle">
                
              </div>
            </div>
          </div>
        </div>
        <div className="row features_row">
          {/* Features Item */}
          <div className="col-lg-3 col-md-6 team_col">
            <div className="feature text-center trans_400">
              <div className="feature_icon py-0">
                <img src={ROOTS} alt="ROOTS" width="200" height="200" />
              </div>
              <div className="feature_text pt-5">
              <h3 className="feature_title">ROOTS</h3>
                <p>Program Anti Bullying</p>
              </div>
            </div>
          </div>
          <div className="col-lg-3 feature_col">
            <div className="feature text-center trans_400">
              <div className="feature_icon">
                <img src={PADUS} alt="PADUS" width="200" height="200"/>
              </div>
              <div className="feature_text pt-5">
              <h3 className="feature_title">PADUS</h3>
                <p>Paduan Suara</p>
              </div>
            </div>
          </div>
          <div className="col-lg-3 feature_col">
            <div className="feature text-center trans_400">
              <div className="feature_icon">
              <img src={PRAMUKA} alt="PRAMUKA" width="200" height="200"/>
              </div>
              <div className="feature_text pt-5">
              <h3 className="feature_title">PRAMUKA</h3>
                <p>Gerakan Pramuka</p>
              </div>
            </div>
          </div>
          <div className="col-lg-3 feature_col">
            <div className="feature text-center trans_400">
              <div className="feature_icon">
              <img src={PMR} alt="Gambar 4" width="200" height="200"/>
              </div>
              <div className="feature_text pt-5">
              <h3 className="feature_title">PMR</h3>
                <p>Palang Merah Remaja</p>
              </div>
            </div>
          </div>
          <div className="col-lg-3 feature_col">
            <div className="feature text-center trans_400">
              <div className="feature_icon">
              <img src={EC} alt="EC" width="200" height="200"/>
              </div>
              <div className="feature_text pt-5">
              <h3 className="feature_title">ENGLISH CLUB</h3>
                <p>Club Bahasa Inggris</p>
              </div>
            </div>
          </div>
          <div className="col-lg-3 feature_col">
            <div className="feature text-center trans_400">
              <div className="feature_icon">
              <img src={FUTSAL} alt="FUTSAL" width="200" height="200"/>
              </div>
              <div className="feature_text pt-5">
              <h3 className="feature_title">FUTSAL</h3>
                <p>Olah Raga Futsal</p>
              </div>
            </div>
          </div>
          <div className="col-lg-3 feature_col">
            <div className="feature text-center trans_400">
              <div className="feature_icon">
              <img src={BTQ} alt="BTQ" width="200" height="200"/>
              </div>
              <div className="feature_text pt-5">
              <h3 className="feature_title">BTQ</h3>
                <p>Baca Tulis Al-Qur'an</p>
              </div>
            </div>
          </div>
          <div className="col-lg-3 feature_col">
            <div className="feature text-center trans_400">
              <div className="feature_icon">
              <img src={TARI} alt="TARI" width="200" height="200"/>
              </div>
              <div className="feature_text pt-5">
              <h3 className="feature_title">TARI</h3>
                <p>Seni Tari</p>
              </div>
            </div>
          </div>
        
        </div>
      </div>
    </div>
    </div>
  );
};

export default FeaturesSection;
